@font-face {
  font-family: "Aktiv Grotesk";
  src: url(~/assets/fonts/fa975079-4469-4091-9fc9-183abad833c4.eot?#iefix);
  src: url(~/assets/fonts/fa975079-4469-4091-9fc9-183abad833c4.eot?#iefix) format("eot"),
url(~/assets/fonts/6f12fb43-3c0d-4e65-b963-d90fd460da2b.woff2) format("woff2"),
  url(~/assets/fonts/820c18fb-231f-4c6b-9d80-103c1272e85e.woff) format("woff"),
  url(~/assets/fonts/9f45f099-c964-4cd7-b419-0974eb850b75.ttf) format("truetype");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url(~/assets/fonts/453d58d2-f02d-4381-abce-a89ada26c6e8.eot?#iefix);
  src: url(~/assets/fonts/453d58d2-f02d-4381-abce-a89ada26c6e8.eot?#iefix) format("eot"),
  url(~/assets/fonts/d7ce1000-6400-40ef-b324-f6c3696fbcf3.woff2) format("woff2"),
  url(~/assets/fonts/80ce2b50-0f08-4fa1-bb2d-6cd6562ea5c1.woff) format("woff"),
  url(~/assets/fonts/c762477d-e236-42c0-baae-31832dc6b62b.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url(~/assets/fonts/fad23da6-2be7-44c7-b756-46c211981aa1.eot?#iefix);
  src: url(~/assets/fonts/fad23da6-2be7-44c7-b756-46c211981aa1.eot?#iefix) format("eot"),
  url(~/assets/fonts/e0d3dfb2-6091-4d80-93b6-be8f035a6168.woff2) format("woff2"),
  url(~/assets/fonts/29d07f2c-dcc2-4981-b4de-0395fb843fda.woff) format("woff"),
  url(~/assets/fonts/132732c9-d900-4258-80c0-a826d6d27598.ttf) format("truetype");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url(~/assets/fonts/5192d96d-3f85-4598-b975-5a464c861ecb.eot?#iefix);
  src: url(~/assets/fonts/5192d96d-3f85-4598-b975-5a464c861ecb.eot?#iefix) format("eot"),
  url(~/assets/fonts/c023bdb4-83b1-4ddf-9f8e-dabf46e929af.woff2) format("woff2"),
  url(~/assets/fonts/2c8d2d95-37b5-4199-9709-49924e18487c.woff) format("woff"),
  url(~/assets/fonts/70870234-c826-4d97-89f2-683dbb7c87e3.ttf) format("truetype");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url(~/assets/fonts/NotoSansThai-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url(~/assets/fonts/NotoSansThai-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}
