// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$layout-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $layout-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $layout-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $layout-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $layout-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $layout-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $layout-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $layout-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $layout-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}
// width in columns (e.g. 3 columns)
@function -width($_columns : 1, $_gutter: 10px, $_totalGrid: 12) {
  // edge17 needs a lower factor
  @return calc((100% - (#{$_gutter} * (#{$_totalGrid} - 1))) / #{$_totalGrid} * #{$_columns} + ((#{$_columns} - 1) * (#{$_gutter} * 0.999))); // FIXED HALF PX PROBLEM (CUT OFF BORDER)
}

@mixin _grid($grid: 6, $breakpoint: $layout-breakpoints--desktop, $_selector: '*') {
  @each $name, $value in $breakpoint {
    $total: map_get($cols, $name);
    $gridcolumns: $total / $grid ;
    $gutter: map_get($gutters, $name);
    $nth-child-horizontal: '#{$grid}n+1';
    $nth-child-vertical: '-n+#{$grid}';

    @include media-breakpoint-only($name) {
      display: flex;
      flex-wrap: wrap;

      //margin top instead of margin bottom
      > #{$_selector} {
        width: -width($gridcolumns, $gutter, $total);
      }

      > #{$_selector} {
        &:nth-child(n) {
          @include margin(top);

          &:nth-of-type(#{$nth-child-vertical}) {
            margin-top: 0;
          }
        }
      }

      > #{$_selector} ~ #{$_selector} {
        &:nth-of-type(n) {
          @include margin(left);

          &:nth-of-type(#{$nth-child-horizontal}) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
@function last($list) {
  @return nth($list, length($list));
}

@function first($list) {
  @return nth($list, 1);
}

@function second($list) {
  @return nth($list, 2);
}

@function column($breakpoint, $gutter) {
  @return (map_get($content-widths, $breakpoint) / map_get($cols, $breakpoint) - $gutter);
}

@function columns($count, $breakpoint) {
  $gutter: map_get($gutters, $breakpoint);
  $column: column($breakpoint, $gutter);

  @return $column * $count + ($gutter * ($count - 1));
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin column($count, $marginleft: false, $availablebreakpoints: ($layout-breakpoints--desktop)) {
  @each $name, $value in $availablebreakpoints {
    @include media-breakpoint-only($name) {
      $gutter: map_get($gutters, $name);
      $column: column($name, $gutter);

      width: columns($count, $name);

      @if ($marginleft == true) {
        & + * {
          margin-left: $gutter;
        }
      }
    }
  }
}

@mixin padding($direction: '') {
  @each $name, $value in $layout-breakpoints {
    @include media-breakpoint-only($name) {
      $gutter: map_get($gutters, $name);

      @if ($direction != '') {
        padding-#{$direction}: $gutter;
      } @else {
        padding: $gutter;
      }
    }
  }
}

@mixin margin($direction: '') {
  @each $name, $value in $layout-breakpoints {
    @include media-breakpoint-only($name) {
      $gutter: map_get($gutters, $name);

      @if ($direction != '') {
        margin-#{$direction}: $gutter;
      } @else {
        margin: $gutter;
      }
    }
  }
}

@mixin center--nested($breakpoint, $level: 0) {
  $gutter: map_get($gutters, $breakpoint);
  $column: column($breakpoint, $gutter);

  @include media-breakpoint-only($breakpoint) {
    max-width: map_get($content-widths, $breakpoint);

    @if $level == 0 {
      padding-left: $gutter / 2;
      padding-right: $gutter / 2;
    } @else if $level == 1 {
      padding-left: columns(1, $breakpoint) + $gutter + $gutter / 2;
      padding-right: columns(1, $breakpoint) + $gutter + $gutter / 2;
    } @else if $level == 2 {
      padding-left: columns(3, $breakpoint) + $gutter + $gutter / 2;
      padding-right: columns(1, $breakpoint) + $gutter + $gutter / 2;
    }
  }
}

@mixin center($level: 0) {
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @each $name, $value in $layout-breakpoints--desktop {
    @include center--nested($name, $level);
  }
  @each $name, $value in $layout-breakpoints--tablet {
    @include center--nested($name, $level);
  }
  @each $name, $value in $layout-breakpoints--phone {
    @include center--nested($name, $level);
  }
}
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

$fontsizes: (
  h1: (
    size: (
      phone: 48px,
      tablet: 72px,
      desktop: 96px
    ),
    line-height: (
      phone: 56px,
      tablet: 80px,
      desktop: 104px
    )
  ),
  h2: (
    size: (
      phone: 40px,
      tablet: 48px,
      desktop: 72px
    ),
    line-height: (
      phone: 48px,
      tablet: 56px,
      desktop: 80px
    )
  ),
  h3: (
    size: (
      phone: 32px,
      tablet: 32px,
      desktop: 48px
    ),
    line-height: (
      phone: 40px,
      tablet: 40px,
      desktop: 56px
    )
  ),
  h4: (
    size: (
      phone: 24px,
      tablet: 28px,
      desktop: 32px
    ),
    line-height: (
      phone: 32px,
      tablet: 40px,
      desktop: 40px
    )
  ),
  large: (
    size: (
      phone: 24px,
      tablet: 24px,
      desktop: 32px
    ),
    line-height: (
      phone: 36px,
      tablet: 36px,
      desktop: 48px
    )
  ),
  regular: (
    size: (
      phone: 20px,
      tablet: 20px,
      desktop: 28px
    ),
    line-height: (
      phone: 32px,
      tablet: 32px,
      desktop: 40px
    )
  ),
  small: (
    size: (
      phone: 16px,
      tablet: 18px,
      desktop: 24px
    ),
    line-height: (
      phone: 24px,
      tablet: 24px,
      desktop: 32px
    )
  ),
  xsmall: (
    size: (
      phone: 14px,
      tablet: 16px,
      desktop: 22px
    ),
    line-height: (
      phone: 22px,
      tablet: 22px,
      desktop: 30px
    )
  )
) !default;

@mixin font($name_) {
  @each $name, $value in $fontsizes {
    @if ($name == $name_) {
      @include media-breakpoint-up(desktop) {
        font-size: map-deep-get($fontsizes, $name, size, desktop);
        line-height: map-deep-get($fontsizes, $name, line-height, desktop);
      }
      @include media-breakpoint-between(tablet-landscape, notebook) {
        font-size: map-deep-get($fontsizes, $name, size, tablet);
        line-height: map-deep-get($fontsizes, $name, line-height, tablet);
      }
      @include media-breakpoint-down(tablet-portrait) {
        font-size: map-deep-get($fontsizes, $name, size, phone);
        line-height: map-deep-get($fontsizes, $name, line-height, phone);
      }
    }
  }
}
$layout-breakpoints: (
  min: 319px,
  smartphone-portrait: 374px,
  smartphone-portrait--max: 413px,
  smartphone-landscape: 575px,
  tablet-portrait: 767px,
  tablet-landscape: 1023px,
  notebook: 1279px,
  desktop: 1439px,
  widescreen: 1679px,
  tv: 1919px,
  max: 10000px
) !default;

$layout-breakpoints--phone: min, smartphone-portrait, smartphone-portrait--max;
$layout-breakpoints--tablet: smartphone-landscape, tablet-portrait;
$layout-breakpoints--desktop: tablet-landscape, notebook, desktop, widescreen, tv, max;

$content-widths: (
  min: 290px,
  smartphone-portrait: 352px,
  smartphone-portrait--max: 384px,
  smartphone-landscape: 544px,
  tablet-portrait: 736px,
  tablet-landscape: 960px,
  notebook: 1152px,
  desktop: 1248px,
  widescreen: 1344px,
  tv: 1632px,
  max: 10000px
) !default;

$gutters: (
  min: 24px,
  smartphone-portrait: 24px,
  smartphone-portrait--max: 32px,
  smartphone-landscape: 32px,
  tablet-portrait: 32px,
  tablet-landscape: 32px,
  notebook: 32px,
  desktop: 40px,
  widescreen: 40px,
  tv: 40px,
  max: 40px
) !default;

$cols: (
  min: 2,
  smartphone-portrait: 2,
  smartphone-portrait--max: 2,
  smartphone-landscape: 4,
  tablet-portrait: 4,
  tablet-landscape: 12,
  notebook: 12,
  desktop: 12,
  widescreen: 12,
  tv: 12,
  max: 12
) !default;

$offsets: (
  xxs: 4px,
  xs: 8px,
  s: 16px,
  base: 24px,
  m: 32px,
  l: 48px,
  xl: 64px,
  xxl: 128px
) !default;

$colors: (
  black: #000,
  white: #fff,
  green: rgb(28, 186, 128),
  blue: rgb(26, 160, 225),
  purple: rgb(125, 89, 197),
  yellow: rgb(255, 241, 54),
  pink: rgb(234, 22, 140)
) !default;
$vdg-fallback-image: '';
@import '~vidage/src/styles/vidage';

.cov-stage {
  $stage: &;
  position: relative;
  max-width: map_get($content-widths, tv);
  margin-left: auto;
  margin-right: auto;

  &__inner {
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(tablet-portrait) {
      &:before {
        padding-top: 36%;
        content: '';
        display: block;
      }
    }
  }

  &__media {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include media-breakpoint-down(smartphone-landscape) {
      position: relative;
      left: auto;
      top: auto;
      height: auto;

      &:before {
        padding-top: 36%;
        content: '';
        display: block;
      }
    }

    #{$stage}__image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
